import React from "react";
import img from "../../assets/pngegg.webp";
const PageWrapper = ({ children, title, sub }) => {
  return (
    <div className="flex flex-1 bg-movies bg-[length:500px_auto] ">
      <div className="flex flex-1 flex-col items-center justify-around bg-black bg-opacity-80 md:flex-row 3xl:justify-center 3xl:gap-[10rem] ">
        <div className="hidden flex-col items-center gap-6 md:flex">
          <img src={img} alt="logo" className="h-36" />
          <div className="flex flex-col items-center">
            <p className="text-sm text-text">
              Watch Latest Movies, Shows, & Sports with
            </p>
            <p className="text-3xl font-bold text-primary-100">
              Unlimited Fun & Masti
            </p>
          </div>
        </div>
        <div className="my-2 flex w-full flex-col items-center  gap-6 px-8 py-20 drop-shadow-lg backdrop-blur-sm md:w-auto md:rounded-lg md:bg-secondary3 md:py-8 md:backdrop-blur-0">
          <div className="flex flex-col items-center gap-2">
            <h1 className="text-xl font-bold text-white">{title}</h1>
            <p className="text-xs text-text">{sub}</p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
