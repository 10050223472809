import { Route, Routes } from "react-router-dom";
import Layout from "./components/commen/Layout/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";

import "swiper/css";
import MovieDetails from "./pages/MovieDetails";
import SeriesDetails from "./pages/SeriesDetails";
import WatchList from "./pages/WatchList";
import Account from "./pages/profile/Account";
import Rented from "./pages/profile/Rented";
import Subscription from "./pages/profile/Subscription";
import AllSubscrition from "./pages/AllSubscrition";
import Category from "./pages/Category";
import Terms from "./pages/static/Terms";
import Privacy from "./pages/static/Privacy";
import About from "./pages/static/About";
import RefundPolicy from "./pages/static/Refund";
import SearchMobile from "./pages/Search";
import ViewAll from "./pages/ViewAll";
import SubscriptionHistory from "./pages/profile/SubsHistory";
import Return from "./pages/static/Return";
import ContactUs from "./pages/static/ContactUs";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/my-account/subscribe/update"
          element={
            <Layout>
              <Subscription />
            </Layout>
          }
        />
        <Route
          path="/my-account/subscriptions-history"
          element={
            <Layout>
              <SubscriptionHistory />
            </Layout>
          }
        />
        <Route
          path="/subscription"
          element={
            <Layout>
              <AllSubscrition />
            </Layout>
          }
        />
        <Route
          path="/view-all/:id"
          element={
            <Layout>
              <ViewAll />
            </Layout>
          }
        />
        <Route
          path="/return-policy"
          element={
            <Layout>
              <Return />
            </Layout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
        />
        <Route
          path="/login"
          element={
            <Layout>
              <Login />
            </Layout>
          }
        />
        <Route
          path="/category/:slug/:id"
          element={
            <Layout active>
              <Category />
            </Layout>
          }
        />
        <Route
          path="/watchlist"
          element={
            <Layout>
              <WatchList />
            </Layout>
          }
        />
        <Route
          path="/rented"
          element={
            <Layout>
              <Rented />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout active>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/movie/:id"
          element={
            <Layout>
              <MovieDetails />
            </Layout>
          }
        />
        <Route
          path="/series/:id"
          element={
            <Layout>
              <SeriesDetails />
            </Layout>
          }
        />
        <Route
          path="/my-account"
          element={
            <Layout>
              <Account />
            </Layout>
          }
        />
        <Route
          path="/my-account/rented"
          element={
            <Layout>
              <Rented />
            </Layout>
          }
        />
        <Route
          path="/termsandconditions"
          element={
            <Layout>
              <Terms />
            </Layout>
          }
        />
        <Route
          path="/privacyPolicy"
          element={
            <Layout>
              <Privacy />
            </Layout>
          }
        />
        <Route
          path="/about-us"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/refund-policy"
          element={
            <Layout>
              <RefundPolicy />
            </Layout>
          }
        />
        <Route
          path="/search"
          element={
            <Layout>
              <SearchMobile />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
