import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/commen/Modal";
import {
  buySubscription,
  checkAccess,
  generateRandomString,
  getAllSubscriptions,
  getOtherSub,
  getOtherSubs,
  getProfile,
  getSubStatus,
  phonePay,
  updateSubscription,
  url,
} from "../utils/apis";
import useStore from "../utils/store";
import { BsFillPatchCheckFill } from "react-icons/bs";
import Loading from "../components/commen/Loading";

const AllSubscrition = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const getOwnSubscriptions = async () => {
    setLoading(true);
    const [success, response] = !profile?.subscriptionValid
      ? await getAllSubscriptions()
      : await getOtherSubs(user.token);
    if (success) {
      setSubscriptions(response);
    }
    setLoading(false);
  };


  const checkAccessHandler = async () => {
    const [success, response] = await getSubStatus(user?.token);
    if (success) {
      setProfile(response);
    }
  };

  const buySubscriptionHandler = async (id) => {
    if (!user) {
      navigate("/login");
    }
    setLoading(true);
    const [success, response] = !profile?.subscriptionValid
      ? await buySubscription(user.token, {
          subscriptionId: id,
        })
      : await updateSubscription(user.token, {
          subscriptionId: id,
        });

    if (success) {
      getOwnSubscriptions();
      setSuccess(true);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getOwnSubscriptions();
  }, [profile]);

  React.useEffect(() => {
    checkAccessHandler();
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="flex flex-1 justify-center py-10">
      <div className="grid w-[80%] gap-10  md:grid-cols-2 lg:grid-cols-3">
        {subscriptions?.map((item) => (
          <Card
            data={item}
            onClick={() => buySubscriptionHandler(item._id)}
            key={item._id}
          />
        ))}
      </div>
      {success && (
        <Modal>
          <div className="flex flex-col items-center justify-center gap-4 rounded bg-white py-10">
            <BsFillPatchCheckFill className="text-7xl text-green-600" />
            <div className="flex flex-col items-center gap-1">
              <h1 className="text-xl font-semibold">
                You have successfully subscribed
              </h1>
              <p className="text-sm  text-gray-600">
                Thanks for subscribing to our service
              </p>
            </div>
            <button
              onClick={() => navigate("/my-account")}
              className="rounded-md bg-primary-100 px-4 py-2 font-semibold text-white"
            >
              Enjoy Watching
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AllSubscrition;

const Card = ({ data, onClick }) => {
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);

  const payHandler = async () => {
    setLoading(true);
    const [success, response] = await phonePay(
      {
        amount: data?.price * 100,
        callbackUrl: `${url}/callback?purchaseType=subscription&id=${data?._id}&userId=${user?.user?._id}`,
        deviceContext: {
          deviceOS: "ANDROID",
        },
        merchantId: "",
        redirectUrl: `https://www.dekhho.com`,
        redirectMode: "GET",
        merchantTransactionId: generateRandomString(10).toUpperCase(),
        merchantUserId: user?.user?._id,
        mobileNumber: "9876543210",
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      },
      user.token
    );

    if (success) {
      window.location.replace(
        response?.data?.data?.instrumentResponse?.redirectInfo?.url
      );
    }
    setLoading(false);
  };

  return (
    <div className=" h-fit rounded-md bg-white">
      <div className=" rounded-t-md bg-gradient-to-r from-primary-100 via-primary-100 to-primary2 py-5 px-10 text-center text-sm font-semibold uppercase text-white  ">
        {data?.title}
      </div>
      <div className="flex flex-col gap-3 px-4 pt-4 pb-2 ">
        <CardItem title="Price" value={data?.price} />
        <CardItem title="Validity" value={data?.validity + " " + "days"} />
        <CardItem title="Movies" value={data?.moviesCount} />
        <CardItem title="Series" value={data?.seriesCount} />
      </div>
      <div className="flex flex-row items-center justify-center pt-2  pb-4">
        <button
          onClick={payHandler}
          className="rounded-md bg-primary-100 px-4 py-2 font-semibold text-white hover:bg-opacity-80"
        >
          {loading ? "Loading..." : "Subscribe"}
        </button>
      </div>
    </div>
  );
};

const CardItem = ({ title, value }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-3">
      <p className="text-sm font-[600]">{title}</p>
      <p className="font-[600]  text-primary-100"> {value} </p>
    </div>
  );
};
