import React from "react";
import img from "../assets/pngegg.webp";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { FaApple, FaFacebookF } from "react-icons/fa";
import PageWrapper from "../components/Auth/PageWrapper";
import OAuth from "../components/Auth/OAuth";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebaseconfig";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Loading2 from "../components/commen/Loading2";
import OtpInput from "react18-input-otp";
import { loginUser, signUpUser } from "../utils/apis";
import useStore from "../utils/store";
import Input from "../components/commen/Input";
const defaultImage =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
const Login = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [otpSent, setOtpSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const loginState = useStore((state) => state.login);
  const [signUpData, setSignUpData] = React.useState({});

  const signUpHandler = async () => {
    setLoading(true);
    if (
      !signUpData.name ||
      (email === "" && !signUpData.email) ||
      phone.length !== 10
    ) {
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("phoneNumber", "+91" + phone);
    formData.append("name", signUpData.name);
    formData.append("email", email ? email : signUpData.email);
    if (signUpData?.image) {
      formData.append("image", signUpData?.image);
    } else {
      formData.append("imageUrl", signUpData?.imageUrl ?? defaultImage);
    }

    const [success, response] = await signUpUser(formData);

    if (success) {
      loginState({
        ...response,
        loginWith: email ? "email" : "phone",
      });
      navigate("/", {
        replace: true,
      });
    }
    setLoading(false);
  };

  const login = async () => {
    setLoading(true);
    try {
      if (phone.length !== 10) {
        setLoading(false);
        return;
      }
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
        auth
      );

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        "+91" + phone,
        recaptchaVerifier
      );
      window.confirmationResult = confirmationResult;
      setOtpSent(true);
    } catch (err) {}
    setLoading(false);
  };

  const verifyOtp = async () => {
    setLoading(true);
    try {
      if (otp.length !== 6) {
        setLoading(false);
        return;
      }
      await window.confirmationResult.confirm(otp);
      const [success, response] = await loginUser({
        phoneNumber: "+91" + phone,
      });

      if (success) {
        loginState({
          ...response,
          loginWith: "phone",
        });

        navigate("/", {
          replace: true,
        });
      }
      if (response?.response.data.message === "user doesn't exist") {
        setSignUp(true);
      }
    } catch (err) {}
    setLoading(false);
  };

  return (
    <PageWrapper
      title={otpSent ? (signUp ? "Sign up" : "OTP") : "Sign in"}
      sub={
        otpSent ? (
          signUp ? (
            `Fill the details to create your account on ${phone}`
          ) : (
            <>
              Check your <span className="text-primary-100"> {phone} </span>this
              number and put the 6 digit code
            </>
          )
        ) : (
          " It's EASY! Just provide your phone number & follow"
        )
      }
    >
      <div className="flex w-full flex-col items-center gap-5 px-3 ">
        {loading ? (
          <Loading2 />
        ) : otpSent ? (
          signUp ? (
            <>
              <label
                htmlFor="file"
                className="group relative cursor-pointer overflow-hidden rounded-full"
              >
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 text-white opacity-0 transition-all group-hover:bg-opacity-40 group-hover:opacity-100">
                  Edit
                </div>

                {signUpData?.image ? (
                  <img
                    src={
                      signUpData?.image
                        ? URL.createObjectURL(signUpData?.image)
                        : defaultImage
                    }
                    alt="profile"
                    className="h-20 w-20 rounded-full"
                  />
                ) : (
                  <img
                    src={signUpData?.imageUrl ?? defaultImage}
                    alt="profile"
                    className="h-20 w-20 rounded-full"
                  />
                )}
              </label>

              <Input
                type="text"
                placeholder="Name"
                value={signUpData.name}
                onChange={(e) =>
                  setSignUpData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              {!email ? (
                <Input
                  type="text"
                  placeholder="Email"
                  value={signUpData.email}
                  onChange={(e) =>
                    setSignUpData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                />
              ) : (
                <Input
                  type="text"
                  placeholder="Phone Number"
                  inputMode="numeric"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className=" w-full rounded border border-text bg-transparent px-3 py-2.5 text-xs font-bold text-text outline-none focus:border-primary-100"
                />
              )}
              <input
                hidden
                id="file"
                type="file"
                placeholder="Upload your profile picture"
                onChange={(e) =>
                  setSignUpData((prev) => ({
                    ...prev,
                    image: e.target.files[0],
                  }))
                }
              />
              <button
                onClick={signUpHandler}
                className="w-full  rounded bg-primary-100  p-2.5 text-xs font-semibold  text-white shadow-primary2 transition-all    hover:shadow-primary"
              >
                Sign up
              </button>
            </>
          ) : (
            <>
              <OtpInput
                numInputs={6}
                value={otp}
                onChange={setOtp}
                separator={<span className="w-1.5"></span>}
                inputStyle="flex-1 h-10 text-center text-text bg-secondary3  border-text border px-3 py-2.5 rounded outline-none"
                containerStyle="otpinput w-full "
              />

              <div className="flex w-full gap-6">
                <button
                  onClick={() => navigate("/login")}
                  className="w-full  rounded  from-primary-100   p-2.5 text-xs  font-semibold text-white transition-all  hover:bg-gradient-to-br"
                >
                  Back
                </button>
                <button
                  onClick={verifyOtp}
                  className="w-full  rounded  bg-primary-100  p-2.5  text-xs font-semibold text-white transition-all   hover:bg-gradient-to-br"
                >
                  Proceed
                </button>
              </div>
            </>
          )
        ) : (
          <>
            <Input
              type="text"
              placeholder="Phone Number"
              inputMode="numeric"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className=" w-full rounded border border-text bg-transparent px-3 py-2.5 text-xs font-bold text-text outline-none focus:border-primary-100"
            />
            <button
              onClick={login}
              className="w-full  rounded bg-gradient-to-bl from-primary-100 to-[#13376a] p-2.5 text-xs font-semibold  text-white shadow-primary2 transition-all hover:bg-gradient-to-br   hover:shadow-primary"
            >
              Sign in
            </button>
          </>
        )}
        {!signUp && (
          <OAuth
            setEmail={setEmail}
            setOtpSent={setOtpSent}
            setSignUp={setSignUp}
            setLoading={setLoading}
            setSignupData={setSignUpData}
          />
        )}
      </div>
      <div id="recaptcha-container" style={{ display: "none" }}></div>
    </PageWrapper>
  );
};

export default Login;
