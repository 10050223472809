import React from "react";
import Static, { Item } from "../../components/commen/Static";

const RefundPolicy = () => {
  return (
    <Static title={"Refund Policy"}>
      <Item title={""}>
        <p>
          This Privacy Policy (&ldquo;Privacy Policy&rdquo;) applies to DEKHHO
          TELEFILMS Site/ App platforms including without limitation&nbsp;
          <a href="www.dekhho.com">
            <span>www.dekhho.com</span>
          </a>
          (
          <a href="https://www.dekhho.com">
            <span>https://www.dekhho.com</span>
          </a>
          ) and other related Site/s or App/s, mobile applications and other
          online features each a &ldquo;Site/s or App/s&rdquo;.
        </p> <br/>
        <p>
          We have provided extensive information for you to view the packages
          before choosing to subscribe to us. If you have any questions or
          reservations, please contact us at support@dekhho.com prior to
          subscribing to our services
        </p>
        <p>
          We, being the service providers for contents available through SITE or
          APP, where you are expected to view packages of your choice after
          being paid for subscription; unfortunately, all fees to DEKHHO
          TELEFILMS for such services are non-refundable.
        </p>
        <p>
          In case, because of any technical glitch at the time of online
          transaction, the transaction does not occur, the amount in process of
          transfer by default goes back into your bank account, automatically
          through Payment Gateway.
        </p>
        <br/>
        <b>CHARGEBACKS</b>
        <p>
          If we receive a chargeback or payment dispute from a credit card
          company or bank, your service and/or subscription will be suspended
          without notice. Applicable chargeback fee will be issued to recover
          fees passed on to us by the credit company, plus any outstanding
          balances accrued as a result of the chargeback(s) must be paid in full
          before service is restored. Instead of issuing a chargeback, contact
          us to address any billing issues. Requesting a chargeback or opening
          any sort of dispute for a valid charge from us is fraud, and is never
          an appropriate or legal means of obtaining a refund.
        </p>
        <p>
          PLEASE READ AND MAKE SURE YOU FULLY UNDERSTAND OUR REFUND POLICY PRIOR
          TO MAKING A PAYMENT.
        </p>
      </Item>
    </Static>
  );
};

export default RefundPolicy;
