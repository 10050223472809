import React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const useValidate = (fun) => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  React.useEffect(() => {
    if (isLoggedIn && user) {
      fun();
    } else {
      navigate("/login");
    }
  }, [isLoggedIn, navigate, user]);

  return [user, navigate];
};

export default useValidate;
