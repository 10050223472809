import React from "react";

const Modal = ({ onClose, children, height, width }) => {
  return (
    <div className="fixed z-50">
      <div
        onClick={onClose}
        className="fixed top-0 left-0 z-40 h-full w-full bg-black bg-opacity-50"
      ></div>

      <div
        className={`fixed top-1/2 left-1/2 z-50 ${height ? height : "h-96"} ${
          width ? width : "w-96"
        } -translate-x-1/2 -translate-y-1/2 transform rounded-lg `}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
