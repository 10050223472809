import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/commen/Loading";
import VideoCard from "../components/VideoCards/VideoCard";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import ReactPlayer from "react-player/youtube";

import {
  addMovieView,
  addRecentWatch,
  addToWatchList,
  checkAccess,
  checkUserWatchlist,
  getMovieDetails,
  getSimilarMovies,
  removeFromWatchList,
  rentAMovie,
} from "../utils/apis";
import { TbDeviceWatchStats, TbDeviceWatchOff } from "react-icons/tb";
import { BsFillPlayFill } from "react-icons/bs";
import useStore from "../utils/store";

import MovieModal from "../components/MovieModal";

import VideoPlayer from "../components/VideoCards/VideoPlayer";
const MovieDetails = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const user = useStore((state) => state.user);
  const [isWatchList, setIsWatchList] = React.useState(false);
  const [similarMovies, setSimilarMovies] = React.useState([]);
  const [description, setDescription] = React.useState(false);
  const [access, setAccess] = React.useState({
    access: false,
  });
  const [url, setUrl] = React.useState(null);
  const [watch, setWatch] = React.useState(false);
  const { id: id2 } = useParams();
  const [id, setId] = React.useState(0);
  const navigate = useNavigate();
  const getMovieDetailsHandler = async () => {
    const [success, response] = await getMovieDetails(id2);
    if (success) {
      setData(response);
      setId(response._id);
      if (!user?.token) {
        setUrl(response?.trailer);
      }
    }
  };

  const getSimilarMoviesHandler = async () => {
    const [success, response] = await getSimilarMovies(id);
    if (success) {
      setSimilarMovies(response);
    }
  };

  const checkWatchList = async () => {
    const [success, response] = await checkUserWatchlist(user?.token, id);
    if (success) {
      setIsWatchList(response.isExist);
    }
  };

  const addToWatchListHandler = async () => {
    setIsWatchList(true);
    const [success, response] = await addToWatchList(user?.token, {
      id,
      type: "movie",
    });
    if (success) {
      checkWatchList();
    }
  };

  const removeFromWatchListHanlder = async () => {
    setIsWatchList(false);
    const [success, response] = await removeFromWatchList(user?.token, {
      id,
    });
    if (success) {
      checkWatchList();
    }
  };

  const movieviewHandler = async () => {
    const [success, response] = await addMovieView(user?.token, id);
    if (success) {

    }
  };

  const checkAccessHandler = async () => {
    const [success, response] = await checkAccess(user?.token, {
      type: "Movie",
      movieId: id,
    });

    if (success) {
      setAccess(response);
      if (response.access) {
        setUrl(data?.url);
      } else {
        setUrl(data?.trailer);
      }
    } else {
      setUrl(data?.trailer);
    }
  };

  const addedRecentWatchHandler = async () => {
    const [success, response] = await addRecentWatch(user?.token, {
      id,
      type: "movie",
    });
    if (success) {

    }
  };

  const RentHandler = async () => {
    const [success, response] = await rentAMovie(user?.token, {
      type: "Movie",
      movieId: id,
    });

    if (success) {
      setWatch(false);
      checkAccessHandler();
    }
  };

  React.useEffect(() => {
    setLoading(true);
    getMovieDetailsHandler();

    if (id !== 0 && user?.token) {
      getSimilarMoviesHandler();
      checkWatchList();
      checkAccessHandler();
      addedRecentWatchHandler();
      movieviewHandler();
    }

    setLoading(false);
  }, [id, id2, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="md:px-32">
      <div className="relative aspect-video w-full object-cover md:aspect-[10/4]">
        {/* <div className="absolute top-0 right-0 left-0 z-10 h-[] bg-primary-100"></div> */}
        {!access.access &&
        (!data?.trailer || data.trailer === "undefined") &&
        !url ? (
          <img
            src={data?.horizontalBanner}
            alt="poster"
            className="h-full w-full bg-black object-contain"
          />
        ) : url?.includes("www.youtube.com") ? (
          <ReactPlayer url={url} controls={true} playing={true} width="100%" />
        ) : (
          <VideoPlayer
            hlsSource={url}
            autoPlay={false}
            poster={data?.horizontalBanner}
            videoSource={url}
            selectorId={"video"}
          />
        )}
      </div>
      <div className="  flex flex-row flex-wrap items-center justify-between gap-6 rounded px-8 py-3 text-white md:bg-primary-100 md:py-1.5 ">
        <div className="flex flex-row flex-wrap items-center gap-6">
          <p className="text-sm font-[600]">{data?.name}</p>
          <div className="flex gap-1">
            {data?.genres?.map((item, index) => (
              <>
                <p className="text-xs font-[400]">{item.name}</p>
                {data?.genres?.length > 1 &&
                  data?.genres?.length !== index + 1 && (
                    <p className="text-xs font-[400]">|</p>
                  )}
              </>
            ))}
          </div>
          <p className="text-xs font-[400]">{data?.duration}</p>
          <p className="text-xs font-[400]">{data?.language}</p>
          <p className="text-xs font-[400]">{data?.releaseYear}</p>
        </div>
        <div className="flex gap-6">
          {!access.access && (
            <button
              onClick={() => {
                if (user) {
                  if (access?.oldSubscriptionValid) {
                    setWatch(true);
                  } else {
                    navigate("/subscription");
                  }
                } else {
                  navigate("/login");
                }
              }}
              className="flex items-center gap-1 rounded bg-primary-200 px-5 py-2 text-xs font-semibold uppercase text-white shadow-primary2 hover:shadow-xl "
            >
              <BsFillPlayFill className="text-xl" />
              Watch Now
            </button>
          )}
          {/* {!access.access && (
            <button
              onClick={() => {
                if (user) {
                  setAccess({ access: true });
                } else {
                  navigate("/login");
                }
              }}
              className="flex items-center gap-1 rounded bg-primary-200 px-5 py-2 text-xs font-semibold uppercase text-white shadow-primary2 hover:shadow-xl "
            >
              <BsFillPlayFill className="text-xl" />
              Watch Now
            </button>
          )} */}
          {user && (
            <button
              className="flex items-center gap-1 text-xl hover:text-primary2"
              onClick={() =>
                isWatchList
                  ? removeFromWatchListHanlder()
                  : addToWatchListHandler()
              }
            >
              {isWatchList ? (
                <>
                  <TbDeviceWatchOff />
                  <p className=" text-xs font-[400] ">Remove from Watchlist</p>
                </>
              ) : (
                <>
                  <TbDeviceWatchStats />
                  <p className=" text-xs font-[400] ">Add to Watchlist</p>
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col  gap-3 px-8 py-4 text-white md:py-8">
        <div className="flex flex-col gap-3">
          <div className="text-xs">
            Rating:
            <br />
            <div className="mt-1 flex gap-1">
              {new Array(5)
                .fill(0)
                .map((item, index) =>
                  +data?.rating > index ? (
                    <AiFillStar className="text-primary-100" />
                  ) : (
                    <AiOutlineStar className="text-primary-100" />
                  )
                )}
            </div>
          </div>
          <p className="text-xs">
            Description:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.description?.slice(
                0,
                description ? data?.description?.length : 100
              )}
              <button
                className="ml-1 text-xs font-[400] text-primary-100"
                onClick={() => setDescription(!description)}
              >
                See {description ? "less" : "more"}
              </button>
            </span>
          </p>
          <p className="text-xs">
            Age-Limit:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.ageLimit}
            </span>
          </p>
        </div>

        {data?.cast?.length > 0 && (
          <>
            <p className="text-xs">Cast:</p>
            <div className="flex flex-wrap gap-4 text-white">
              {data.cast?.map((item, index) => (
                <div className="flex flex-col  gap-2 text-xs">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-44 w-28 rounded-md object-cover"
                  />
                  <div>
                    <p>{item.name}</p>
                    <p className="text-text">{item.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {similarMovies.length > 0 && (
        <VideoCard
          data={{
            name: "You might also like",
            imageType: "Vertical",
            content: similarMovies.map((item, index) => ({
              kind: "movie",
              item: item,
            })),
          }}
        />
      )}

      {watch && (
        <MovieModal
          data={data}
          setWatch={setWatch}
          RentHandler={RentHandler}
          access={access}
        />
      )}
    </div>
  );
};

export default MovieDetails;
