import React from "react";
import { useNavigate } from "react-router-dom";
import Void from "../../assets/void.svg";
const HorizontalCards = ({ data, title }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-1  flex-col gap-3 py-6 px-4 md:px-8">
      <h1 className="text-lg font-bold text-text">{title}</h1>
      {data.length > 0 ? (
        <div className="flex flex-row flex-wrap gap-4">
          {data.map((item) => (
            <button
              className="w-[46%] flex-col gap-2 md:w-auto"
              onClick={() => {
                item.kind === "movie"
                  ? navigate(
                      `/movie/${item.item.slug}
                       
                      `
                    )
                  : navigate(`/series/${item.item.slug}`);
              }}
            >
              <img
                src={item.item.horizontalBanner}
                alt="banner"
                className="h-24 w-[1/2]  cursor-pointer rounded-md object-cover transition-all hover:scale-110 md:h-28 md:w-52"
              />
            </button>
          ))}
        </div>
      ) : (
        <div
          className="
        flex flex-col items-center justify-center gap-2
        "
        >
          <img src={Void} alt="void" className=" w-60" />
          <p className="text-center text-text">No Data Found</p>
        </div>
      )}
    </div>
  );
};

export default HorizontalCards;
