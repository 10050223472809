import { async } from "@firebase/util";
import React from "react";
import Loading from "../../components/commen/Loading";
import useValidate from "../../hooks/useValidate";
import { getSubscriptionHistory } from "../../utils/apis";

const SubscriptionHistory = () => {
  const [subscriptionHistory, setSubscriptionHistory] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [user, navigate] = useValidate(getSubscriptionHistoryHandler);
  async function getSubscriptionHistoryHandler() {
    setLoading(true);
    const [success, response] = await getSubscriptionHistory(user.token);
    if (success) {
      setSubscriptionHistory(response);
    }
    setLoading(false);
  }


  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex-1 p-10 ">
      <div className="flex w-auto flex-col  justify-center gap-3 rounded bg-secondary3 py-6 px-10">
        <p className="text-xl font-semibold text-white">Subscription History</p>
        {subscriptionHistory?.[0]?.history?.map((item) => (
          <div className="flex flex-col gap-4  rounded bg-gray-400 bg-opacity-25 py-4 px-5 text-left text-white shadow-sm backdrop-blur-sm">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionHistory;
