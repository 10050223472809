import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/commen/Loading";
import VideoCard from "../components/VideoCards/VideoCard";
import {
  addRecentWatch,
  addSeriesView,
  addToWatchList,
  checkAccess,
  checkUserWatchlist,
  getSeriesDetails,
  getSimilarSeries,
  removeFromWatchList,
  rentASeries,
} from "../utils/apis";
import { TbDeviceWatchStats, TbDeviceWatchOff } from "react-icons/tb";
import { BsFillPlayFill } from "react-icons/bs";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import ReactPlayer from "react-player/youtube";
import useStore from "../utils/store";
import MovieModal from "../components/MovieModal";
import VideoPlayer from "../components/VideoCards/VideoPlayer";
const SeriesDetails = () => {
  const [data, setData] = React.useState([]);

  const { id: id2 } = useParams();
  const [id, setId] = React.useState(0);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const [similarSeries, setSimilarSeries] = React.useState([]);
  const [currentEpisode, setCurrentEpisode] = React.useState(0);
  const [description, setDescription] = React.useState(false);
  const [isWatchList, setIsWatchList] = React.useState(false);
  const [watch, setWatch] = React.useState(false);
  const navigate = useNavigate();
  const [access, setAccess] = React.useState({
    access: false,
  });
  const getSeriesDetailsHandler = async () => {
    const [success, response] = await getSeriesDetails(id2);
    if (success) {
      setId(response[0]._id);
      setData(response[0]);
    }
  };

  const getSimilarSeriesHandler = async () => {
    const [success, response] = await getSimilarSeries(data?._id);
    if (success) {
      setSimilarSeries(response);
    }
  };

  const checkWatchList = async () => {
    const [success, response] = await checkUserWatchlist(
      user?.token,
      data?._id
    );
    if (success) {
      setIsWatchList(response.isExist);
    }
  };

  const addToWatchListHandler = async () => {
    const [success, response] = await addToWatchList(user?.token, {
      id: data?._id,
      type: "series",
    });
    if (success) {
      await checkWatchList();
    }
  };

  const removeFromWatchListHanlder = async () => {
    const [success, response] = await removeFromWatchList(user?.token, {
      id: id,
    });
    if (success) {
      checkWatchList();
    }
  };
  const checkAccessHandler = async () => {
    const [success, response] = await checkAccess(user?.token, {
      type: "Series",
      seriesId: data?._id,
    });
    if (success) {
      setAccess(response);
    }
  };
  const addedRecentWatchHandler = async () => {
    const [success, response] = await addRecentWatch(user?.token, {
      id,
      type: "series",
    });
    if (success) {
    }
  };
  const seriesviewHandler = async () => {
    const [success, response] = await addSeriesView(user?.token, id);
    if (success) {
    }
  };
  const RentHandler = async () => {
    setLoading(true);
    const [success, response] = await rentASeries(user?.token, {
      type: "Series",
      seriesId: id,
    });

    if (success) {
      setWatch(false);
      checkAccessHandler();
    }
    setLoading(false);
  };
  const playerRef = React.useRef(null);

  const url = access?.access
    ? data?.episodes[currentEpisode]?.url
    : data?.trailer;
  React.useEffect(() => {
    setLoading(true);
    getSeriesDetailsHandler();
    if (id !== 0 && user?.token) {
      getSimilarSeriesHandler();
      checkWatchList();
      checkAccessHandler();
      addedRecentWatchHandler();
      seriesviewHandler();
    }
    setLoading(false);
  }, [id, id2, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className=" md:px-32">
      <div className="relative aspect-video w-full object-cover md:aspect-[10/4]">
        {!access.access && (!data?.trailer || data.trailer === "undefined") ? (
          <img
            src={data?.horizontalBanner}
            alt="poster"
            className="h-full w-full bg-black object-contain"
          />
        ) : url?.includes("www.youtube.com") ? (
          <ReactPlayer url={url} controls={true} playing={true} width="100%" />
        ) : (
          <VideoPlayer
            hlsSource={url}
            autoPlay={false}
            poster={data?.horizontalBanner}
            videoSource={url}
            selectorId={"video"}
          />
        )}
      </div>
      <div className="flex  flex-row flex-wrap items-center justify-between gap-6 rounded px-8 py-1.5 text-white md:gap-10 md:bg-primary-100">
        <div className="flex flex-row flex-wrap items-center gap-6">
          <p className="text-sm font-[600]">{data?.name}</p>
          <div className="flex gap-1">
            {data?.genres?.map((item, index) => (
              <>
                <p className="text-xs font-[400]">{item.name}</p>
                {data?.genres?.length > 1 &&
                  data?.genres?.length !== index + 1 && (
                    <p className="text-xs font-[400]">|</p>
                  )}
              </>
            ))}
          </div>
          <p className="text-xs font-[400]">{data?.duration}</p>
          <p className="text-xs font-[400]">{data?.language}</p>
        </div>
        <div className="flex gap-6">
          {!access.access && (
            <button
              onClick={() => {
                if (user) {
                  if (access?.oldSubscriptionValid) {
                    setWatch(true);
                  } else {
                    navigate("/subscription");
                  }
                } else {
                  navigate("/login");
                }
              }}
              className="flex items-center gap-1 rounded bg-primary-200 px-5 py-2 text-xs font-semibold uppercase text-white shadow-primary2 hover:shadow-xl "
            >
              <BsFillPlayFill className="text-xl" />
              Watch Now
            </button>
          )}
          {user && (
            <button
              className="flex items-center gap-1 text-xl hover:text-primary2"
              onClick={() =>
                isWatchList
                  ? removeFromWatchListHanlder()
                  : addToWatchListHandler()
              }
            >
              {isWatchList ? (
                <>
                  <TbDeviceWatchOff />
                  <p className=" text-xs font-[400] ">Remove from Watchlist</p>
                </>
              ) : (
                <>
                  <TbDeviceWatchStats />
                  <p className=" text-xs font-[400] ">Add to Watchlist</p>
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col  gap-3 px-8 py-4 text-white md:pb-5 md:pt-8">
        <div className="flex flex-col gap-3">
          <div className="text-xs">
            Rating:
            <br />
            <div className="mt-1 flex gap-1">
              {new Array(5)
                .fill(0)
                .map((item, index) =>
                  +data?.rating > index ? (
                    <AiFillStar className="text-primary-100" />
                  ) : (
                    <AiOutlineStar className="text-primary-100" />
                  )
                )}
            </div>
          </div>
          <p className="text-xs">
            Description:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.description?.slice(
                0,
                description ? data?.description?.length : 100
              )}
              <button
                className="ml-1 text-xs font-[400] text-primary-100"
                onClick={() => setDescription(!description)}
              >
                See {description ? "less" : "more"}
              </button>
            </span>
          </p>
          <p className="text-xs">
            Age-Limit:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.ageLimit}
            </span>
          </p>
          <p className="text-xs">
            Episodes:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.episodes?.length}
            </span>
          </p>
        </div>
        {/* {add episodes} */}

        {data.cast?.length > 0 && (
          <>
            <p className="text-xs">Cast:</p>
            <div className="flex flex-wrap gap-4 text-white">
              {data.cast?.map((item, index) => (
                <div className="flex flex-col  gap-2 text-xs">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-44 w-28 rounded-md object-cover"
                  />
                  <div>
                    <p>{item.name}</p>
                    <p className="text-text">{item.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <VideoCard
        episode
        data={{
          name: "Episodes",
          imageType: "Horizontal",
          content: data?.episodes?.map((item, index) => ({
            kind: "series",
            item: item,
          })),
        }}
        onClick={(item) => {
          if (user && !access?.access) {
            if (access?.oldSubscriptionValid) {
              setWatch(true);
            } else {
              navigate("/subscription");
            }
          }
          if (!user) {
            navigate("/login");
          }
          if (access?.access) setCurrentEpisode(item);
        }}
      />
      {similarSeries.length > 0 && (
        <VideoCard
          data={{
            name: "You might also like",
            imageType: "Vertical",
            content: similarSeries.map((item, index) => ({
              kind: "series",
              item: item,
            })),
          }}
        />
      )}
      {watch && (
        <MovieModal
          access={access}
          setWatch={setWatch}
          data={data}
          RentHandler={RentHandler}
        />
      )}
    </div>
  );
};

export default SeriesDetails;
