import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";

const Loading = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-col gap-6 pb-5">
      <ContentLoader
        width={"100%"}
        speed={2}
        viewBox="0 0 100% 300"
        className="h-60 w-full"
        //dark
        backgroundColor="#1a1a1a"
        foregroundColor="#2c2c2c"
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="300" />
      </ContentLoader>
      {new Array(2).fill(0).map((item, index) => (
        <div className="flex flex-col gap-5 px-8" key={index}>
          <ContentLoader
            width={"100%"}
            viewBox="0 0 100% 10"
            className="h-8 w-full"
            backgroundColor="#1a1a1a"
            foregroundColor="#2c2c2c"
          >
            <rect
              width="20%"
              height="10"
              //rounded
              rx="2"
              ry="2"
              x="0"
              y="0"
            />
          </ContentLoader>
          <div className="flex gap-3 ">
            {new Array(width > 768 ? 5 : 2).fill(0).map((item, index) => (
              <ContentLoader
                key={index}
                width={width > 768 ? 300 : "50%"}
                className="h-64  md:h-28 "
                backgroundColor="#1a1a1a"
                foregroundColor="#2c2c2c"
                viewBox="0 0 100% 100%"
              >
                <rect
                  width="100%"
                  className="h-64 rounded-md  object-cover md:h-28"
                  //rounded
                  rx="5"
                  ry="5"
                  x="0"
                  y="0"
                />
              </ContentLoader>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Loading;
