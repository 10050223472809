import React from "react";
import { BsCollectionPlay } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Modal from "./commen/Modal";
import { generateRandomString, phonePay, url } from "../utils/apis";
import useStore from "../utils/store";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const MovieModal = ({ setWatch, data, RentHandler, access }) => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);

  const payHandler = async () => {
    setLoading(true);
    const [success, response] = await phonePay(
      {
        amount: data?.price * 100,
        callbackUrl: `${url}/callback?purchaseType=${data?.type}&id=${data?._id}&userId=${user?.user?._id}`,
        deviceContext: {
          deviceOS: "ANDROID",
        },
        merchantId: "OOLALAONLINE",
        redirectUrl: `https://www.dekhho.com`,
        redirectMode: "GET",
        merchantTransactionId: generateRandomString(10).toUpperCase(),
        merchantUserId: user?.user?._id,
        mobileNumber: "9876543210",
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      },
      user.token
    );
    if (success) {
      // window.location.replace(
      //   response?.data?.data?.instrumentResponse?.redirectInfo?.url
      // );
    }
    setLoading(false);
  };

  return (
    <Modal onClose={() => setWatch(false)} height="a">
      <div className="flex flex-col  gap-3 rounded-lg bg-secondary3 py-5">
        <p className="text-center text-lg font-semibold text-white">
          Select Your Prefrence
        </p>
        <div className="flex flex-col gap-3 px-5">
          {access?.isPaid ? (
            <button
              className="flex items-center gap-2 rounded-lg bg-primary-100 px-5 py-2 text-white"
              onClick={payHandler}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="animate-spin text-xl" />
              ) : (
                <FaDollarSign className="text-xl" />
              )}
              Rent Now - ₹{data?.price}
            </button>
          ) : (
            <button
              className="flex items-center gap-2 rounded-lg bg-primary-100 px-5 py-2 text-white"
              onClick={() => {
                navigate("/my-account/subscribe/update", {
                  state: {
                    subs: access?.subscription,
                  },
                });
              }}
            >
              <BsCollectionPlay className="text-xl" />
              Buy Subscription
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MovieModal;
