import React from "react";
import HorizontalCards from "../../components/commen/HorizontalCards";
import Loading from "../../components/commen/Loading";
import useValidate from "../../hooks/useValidate";
import { getAllRentals } from "../../utils/apis";

const Rented = () => {
  const [rented, setRented] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [user, navigate] = useValidate(getRentedHandler);
  async function getRentedHandler() {
    setLoading(true);
    const [success, response] = await getAllRentals(user.token);
    if (success) {
      setRented(response.rentedContent);
    }
    setLoading(false);
  }
  if (loading) {
    return <Loading />;
  }
  return <HorizontalCards data={rented} title={"Rented"} />;
};

export default Rented;
