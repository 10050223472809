import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";
import { FaFacebookF, FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../utils/apis";
import { auth } from "../../utils/firebaseconfig";
import useStore from "../../utils/store";

const OAuth = ({
  setOtpSent,
  setEmail,
  setSignUp,
  setLoading,
  setSignupData,
}) => {
  const loginState = useStore((state) => state.login);
  const navigate = useNavigate();
  const googleLogin = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const [success, response] = await loginUser({
        email: result.user.email,
      });
      if (success) {
        loginState({
          ...response,
          loginWith: "email",
        });
        navigate("/", {
          replace: true,
        });
      }
      if (response.response.data.message === "user doesn't exist") {
        setEmail(result.user.email);
        setSignupData({
          email: result.user.email,
          name: result.user.displayName,
          imageUrl: result.user.photoURL,
        });
        setOtpSent(true);
        setSignUp(true);
      }
    } catch (err) {}
    setLoading(false);
  };

  return (
    <>
      <div className="flex w-full items-center gap-2">
        <div className="h-[1px] flex-1  bg-text"></div>
        <p className="text-[10px] font-semibold text-text  ">
          Or you login with
        </p>
        <div className="h-[1px] flex-1 bg-text"></div>
      </div>
      <div className="flex items-center justify-center gap-6  ">
        <button
          onClick={googleLogin}
          className="  rounded-full bg-white p-2  text-xl text-white transition-all hover:px-6"
        >
          <FcGoogle />
        </button>
        {/* <button className=" text-black rounded-full text-xl  transition-all p-2 bg-white hover:px-6">
          <FaApple />
        </button> */}
      </div>
    </>
  );
};

export default OAuth;
