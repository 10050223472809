import React from "react";
import HorizontalCards from "../components/commen/HorizontalCards";
import { useLocation } from "react-router-dom";

const ViewAll = () => {
  const { state } = useLocation();
  return (
    <HorizontalCards
      data={state?.content.filter((item) => item?.item?.isEnable === true)}
      title={state?.name}
    />
  );
};

export default ViewAll;
