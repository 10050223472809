import React from "react";
import HorizontalCards from "../components/commen/HorizontalCards";
import Loading from "../components/commen/Loading";
import useValidate from "../hooks/useValidate";
import { getWatchList } from "../utils/apis";
import useStore from "../utils/store";

const WatchList = () => {
  const user = useStore((state) => state.user);

  const [watchList, setWatchList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const getWatchListHandler = async () => {
    setLoading(true);
    const [success, response] = await getWatchList(user.token);
    if (success) {
      setWatchList(response);
    }

    setLoading(false);
  };

  useValidate(getWatchListHandler);

  if (loading) {
    return <Loading />;
  }
  return (
    <HorizontalCards
      title={"WatchList"}
      data={watchList?.filter((item) => item.item.isEnable === true)}
    />
  );
};

export default WatchList;
