import React from "react";
import Static, { Item } from "../../components/commen/Static";

const Privacy = () => {
  return (
    <Static title={"Privacy Policy"}>
      <Item title={""}>
        <p>
          This Privacy Policy (&ldquo;Privacy Policy&rdquo;) applies to DEKHHO
          TELEFILMS Site/ App platforms including without limitation&nbsp;
          <a href="www.dekhho.com">
            <span>www.dekhho.com</span>
          </a>{" "}
          (
          <a href="https://www.dekhho.in">
            <span>https://www.dekhho.in</span>
          </a>
          ) and other related Site/s or App/s, mobile applications and other
          online features each a &ldquo;Site/s or App/s&rdquo;.
        </p>
        <p>
          This Privacy Policy should be read in conjunction with the Terms of
          Use available on the &ldquo;Site/s or App/s&rdquo;. Personal
          Information/Data defined below of a user/s is collected if the user/s
          registers with the Site/s or App/s, accesses the Site/s or App/s or
          takes any action on the Site/s or App/s or uses or accesses the
          Services as defined in the Terms of Use. The terms &lsquo;Personal
          Information&rsquo; and &lsquo;Sensitive Personal Information or
          Information&rsquo; shall have the meaning ascribed to it under the
          Indian Information Technology Act, 2000 read with the Information
          Technology.
        </p>
        <p>WHAT DO WE DO WITH YOUR INFORMATION?</p>
        <p>
          When you subscribe to something from our packages as part of the
          buying and selling process, we collect the personal information you
          give us such as your name, address and email address.
        </p>
        <p>
          When you browse our packages, we also automatically receive your
          computer&rsquo;s internet protocol (IP) address in order to provide us
          with information that helps us learn about your browser and operating
          system. Email marketing (if applicable): With your permission, we may
          send you emails about our store, new products and other updates.
        </p>
        <p>CONSENT</p>
        <p>
          When you provide us with personal information to complete a
          transaction, verify your credit card, we imply that you consent to our
          collecting it and using it for that specific reason only.
        </p>
        <p>
          If we ask for your personal information for a secondary reason, like
          marketing, we will either ask you directly for your expressed consent,
          or provide you with an opportunity to say no.
        </p>
        <p>HOW DO I WITHDRAW MY CONSENT?</p>
        <p>
          If after you opt-in, you change your mind, you may withdraw your
          consent for us to contact you, for the continued collection, use or
          disclosure of your information, at anytime, by emailing us at
          support@dekhho.com or contacting us at the address provided on the
          website.
        </p>
        <p>DISCLOSURE</p>
        <p>
          We may disclose your personal information if we are required by law to
          do so or if you violate our Terms of Service.
        </p>
        <p>PAYMENT</p>
        <p>
          We use PhonePe for processing payments. We/PhonePe do not store your
          card data on their servers. The data is encrypted through the Payment
          Card Industry Data Security Standard (PCI-DSS) when processing
          payment. Your purchase transaction data is only used as long as is
          necessary to complete your purchase transaction.
        </p>
        <p>
          After that is complete, your purchase transaction information is not
          saved. Our payment gateway adheres to the standards set by PCI-DSS as
          managed by the PCI Security Standards Council, which is a joint effort
          of brands like Visa, MasterCard, American Express and Discover.
          PCI-DSS requirements help ensure the secure handling of credit card
          information by our store and its service providers. For more insight,
          you may also want to read terms and conditions of PhonePe on&nbsp;
          <a href="https://www.phonepe.com">
            <span>https://www.phonepe.com</span>
          </a>
        </p>
        <p>THIRD-PARTY SERVICES</p>
        <p>
          In general, the third-party providers used by us will only collect,
          use and disclose your information to the extent necessary to allow
          them to perform the services they provide to us.
        </p>
        <p>
          However, certain third-party service providers, such as payment
          gateways and other payment transaction processors, have their own
          privacy policies in respect to the information we are required to
          provide to them for your purchase-related transactions.
        </p>
        <p>
          For these providers, we recommend that you read their privacy policies
          so you can understand the manner in which your personal information
          will be handled by these providers.
        </p>
        <p>
          In particular, remember that certain providers may be located in or
          have facilities that are located at different jurisdiction than either
          you or us. So if you elect to proceed with a transaction that involves
          the services of a third- party service provider, then your information
          may become subject to the laws of the jurisdiction(s) in which that
          service provider or its facilities are located.
        </p>
        <p>
          Once you leave our store&rsquo;s website or are redirected to a
          third-party website or application, you are no longer governed by this
          Privacy Policy or our website&rsquo;s Terms of Service. our
          website&rsquo;s Terms of Service.
        </p>
        <p>LINKS</p>
        <p>
          When you click on links on our website for subscription, they may
          direct you away from our site. We are not responsible for the privacy
          practices of other sites and encourage you to read their privacy
          statements.
        </p>
        <p>SECURITY</p>
        <p>
          To protect your personal information, we take reasonable precautions
          and follow industry best practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or
          destroyed.
        </p>
        <p>COOKIES</p>
        <p>
          We use cookies to maintain session of user/s. It is not used to
          personally identify you on other websites.
        </p>
        <p>AGE OF CONSENT</p>
        <p>
          By using this site, you represent that you are at least the age of
          majority in your state or province of residence, or that you are the
          age of majority in your state or province of residence and you have
          given us your consent to allow any of your minor dependents to use
          this site.
        </p>
        <p>CHANGES TO THIS PRIVACY POLICY</p>
        <p>
          We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the website. If we make
          material changes to this policy, we will notify you here that it has
          been updated, so that you are aware of what information we collect,
          how we use it, and under what circumstances, if any, we use and/or
          disclose it. If our APP is acquired or merged with another company,
          your information may be transferred to the new owners so that we may
          continue to provide services to you.
        </p>
        <p>QUESTIONS AND CONTACT INFORMATION</p>
        <p>
          If you would like to: access, correct, amend or delete any personal
          information we have, register a complaint, or simply want more
          information, mail our Privacy Compliance Officer at support@dekhho.com
          Or by contacting at the company contact which is hereby incorporated
          by this reference into the Terms of Service. The Terms of Service.
        </p>
      </Item>
    </Static>
  );
};

export default Privacy;
