import React from "react";
import Static, { Item } from "../../components/commen/Static";

const ContactUs = () => {
  return (
    <Static title={"Contact Us"}>
      <Item title={""}>
        Name:Shubham Vishwakarma <br />
        Dekhho Telefilms <br />
        Address: E7 Mathura, Evershine Nagar, Malad West Pin-400064 <br />
        Mobile no. +918693051197 <br />
        Email: support@dekhho.com <br />
        dekhhotelefilms@gmail.com <br />
      </Item>
    </Static>
  );
};

export default ContactUs;
