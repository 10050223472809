// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCa6_S3U6YFq5p_SHvb_h31WclG2Ucbotc",
  authDomain: "alt-premier.firebaseapp.com",
  projectId: "alt-premier",
  storageBucket: "alt-premier.appspot.com",
  messagingSenderId: "842745608030",
  appId: "1:842745608030:web:d778f891e2a462c1b541cf",
  measurementId: "G-SP54ESRJHX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);
