import React, { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import img1 from "../../../assets/appstore/1.png";
import Logo from "../../../assets/logo.png";

import { getHomePage } from "../../../utils/apis";
import { GrFormClose } from "react-icons/gr";
import useStore from "../../../utils/store";
import { AiOutlineClose } from "react-icons/ai";
const MobileNavbar = ({ home }) => {
  const activeCategory = useStore((state) => state.activeCategory);
  const setActiveCategory = useStore((state) => state.setActiveCategory);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const logout = useStore((state) => state.logout);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  return (
    <nav className=" flex items-center justify-between bg-black px-6 py-4 md:hidden ">
      <div className="flex items-center gap-2 ">
        <button
          onClick={() => setOpen(!open)}
          className="mr-2 flex flex-col gap-2"
        >
          <div className="h-1 w-8 rounded-full bg-white"></div>
          <div className="h-1 w-8 rounded-full bg-white"></div>
          <div className="h-1 w-8 rounded-full bg-white"></div>
        </button>
        <img
          src={Logo}
          alt="logo"
          className=" mr-auto w-8"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="ml-auto flex gap-2">
        <button
          onClick={() => {
            navigate("/search");
          }}
          className="rounded-full bg-white bg-opacity-25 p-3 text-xl text-text"
        >
          <BsSearch />
        </button>
        {isLoggedIn ? (
          <button
            onClick={() => {
              navigate("/my-account");
            }}
            className="flex items-center gap-2 rounded text-sm text-text hover:text-white"
          >
            Profile
          </button>
        ) : (
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="flex items-center gap-2 rounded text-sm text-text hover:text-white"
          >
            Login
          </button>
        )}
      </div>
      <div
        className={`fixed inset-0 z-40 flex flex-col items-center justify-center gap-6 bg-black bg-opacity-90 backdrop-blur-sm transition-all duration-500 ease-in-out md:hidden
      ${open ? `translate-x-0` : `-translate-x-full`} 
          `}
      >
        <button
          onClick={() => setOpen(false)}
          className="absolute top-0 right-0 p-4"
        >
          <AiOutlineClose className="text-2xl text-white" />
        </button>

        {home?.map((item) => (
          <button
            key={item._id}
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
              }
              setActiveCategory(item._id);
              setOpen(false);
            }}
            className={`flex items-center gap-2 rounded text-lg font-semibold ${
              activeCategory === item._id
                ? ` text-primary-100 `
                : ` text-text hover:text-white`
            }   `}
          >
            {item.name}
          </button>
        ))}
        <button
          onClick={() => {
            if (isLoggedIn) {
              logout();
              setOpen(false);
              navigate("/");
            } else {
              navigate("/login");
              setOpen(false);
            }
          }}
          className={`flex items-center gap-2 rounded text-lg font-semibold text-text hover:text-white`}
        >
          {isLoggedIn ? "Logout" : "Login"}
        </button>

        <a
          href="https://play.google.com/store/apps/details?id=com.alt.alt_premiere"
          className={`flex items-center gap-2 rounded text-sm text-text `}
          target="_blank"
          rel="noreferrer"
        >
          <img src={img1} alt="appstore" className="h-10 md:h-8" />
        </a>
      </div>
    </nav>
  );
};

export default MobileNavbar;
