import React, { useEffect, useState } from "react";
import Logo from "../../../assets/logo.png";
import { MdOutlineSearch } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStore from "../../../utils/store";
import { async } from "@firebase/util";
import { signOut } from "firebase/auth";
import { auth } from "../../../utils/firebaseconfig";
import {
  getAllNotification,
  getHomePage,
  getUnreadNotifications,
  markAsRead,
  searchData,
} from "../../../utils/apis";
import Loading2 from "../Loading2";
import VideoCard from "../../VideoCards/VideoCard";
import { IoCloseSharp, IoNotificationsSharp } from "react-icons/io5";

import { FiChevronRight } from "react-icons/fi";
const Navbar = ({ active, home }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useStore((state) => state.user);

  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const [search, setSearch] = React.useState("");
  const logout = useStore((state) => state.logout);
  const [show, setShow] = React.useState(false);
  const logoutHandler = async () => {
    await signOut(auth);
    logout();
  };
  const [data, setData] = React.useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] =
    React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [notification, setNotification] = React.useState(false);
  const [notificationData, setNotificationData] = React.useState([]);
  const [loading2, setLoading2] = React.useState(false);

  const activeCategory = useStore((state) => state.activeCategory);
  const setActiveCategory = useStore((state) => state.setActiveCategory);
  const links = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Series",
      path: "/series",
    },
    {
      title: "Movies",
      path: "/movies",
    },
    {
      title: "Exclusive",
      path: "/exclusive",
    },
  ];

  const getUnreadNotificationsHandler = async () => {
    const [success, data] = await getUnreadNotifications(user?.token);

    if (success) {
      setUnreadNotificationCount(data.notificationCount);
    }
  };

  const getNotificationData = async () => {
    setLoading2(true);
    const [success, data] = await getAllNotification(user.token);
    if (success) {
      setNotificationData(data);
    }
    setLoading2(false);
  };
  const transfer = (path) => {
    navigate(path);
    setShow(false);
  };

  const searchOnChange = async (e) => {
    setSearch(e.target.value);
    setLoading(true);
    const [success, data] = await searchData(e.target.value);
    if (success) {
      setData({
        movies: data.movies.filter((item) => item.isEnable),
        series: data.series.filter((item) => item.isEnable),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && user) {
      getUnreadNotificationsHandler();
    }
  }, [user, isLoggedIn]);

  useEffect(() => {
    setSearch("");
  }, [location.pathname]);

  return (
    <>
      <nav className="relative hidden flex-col items-center justify-between gap-4 bg-secondary2 py-6 px-6 md:flex md:flex-row md:py-3.5">
        <div className="flex gap-14">
          <img
            src={Logo}
            alt="logo"
            className="h-10 cursor-pointer md:h-8"
            onClick={() => navigate("/")}
          />
          <div className="flex items-center gap-6 ">
            {home?.map((item) => (
              <button
                key={item._id}
                onClick={() => {
                  if (location.pathname !== "/") {
                    navigate("/");
                  }
                  setActiveCategory(item._id);
                }}
                className={`flex items-center gap-2 rounded text-sm ${
                  activeCategory === item._id
                    ? ` text-primary-100 `
                    : ` text-text hover:text-white`
                }   `}
              >
                {item.name}
              </button>
            ))}
            <a
              href="https://play.google.com/store/apps/details?id=com.alt.alt_premiere"
              className={`flex items-center gap-2 rounded text-sm text-text `}
              target="_blank"
              rel="noreferrer"
            >
              Download App
            </a>
          </div>
        </div>
        <div className="flex w-full flex-col gap-6 md:w-auto md:flex-row">
          <div className="flex items-center gap-2 rounded bg-secondary3 py-3 px-2 text-text md:py-2">
            <MdOutlineSearch />
            <input
              type="text"
              placeholder="Search"
              className="bg-transparent text-[13px] text-text outline-none "
              value={search}
              onChange={searchOnChange}
            />
          </div>
          <div className="flex gap-6 self-end md:self-auto">
            {user && (
              <button
                onClick={() => {
                  setNotification(true);
                  getNotificationData();
                }}
                className="relative px-2 text-white"
              >
                <IoNotificationsSharp />
                <div
                  className="
            text-md absolute top-0 right-0 h-4 w-4 rounded-full bg-primary-100 text-xs"
                >
                  {unreadNotificationCount}
                </div>
              </button>
            )}
            {isLoggedIn ? (
              <div className="relative self-end">
                <img
                  src={user?.user?.image}
                  alt="user"
                  className="h-8 w-8 cursor-pointer rounded-full bg-text  "
                  onClick={() => setShow(!show)}
                />
                {show && (
                  <div className=" absolute top-10 right-0 z-50 w-44 rounded bg-secondary3 py-3 pl-4 pr-20">
                    <div className="flex flex-col gap-2">
                      <div
                        className="cursor-pointer text-[13px]  text-text hover:text-white"
                        onClick={() => transfer("/watchlist")}
                      >
                        Watchlist
                      </div>
                      <div
                        className="cursor-pointer text-[13px]  text-text hover:text-white"
                        onClick={() => transfer("/rented")}
                      >
                        Rented
                      </div>
                      <div
                        className="cursor-pointer text-[13px]  text-text hover:text-white"
                        onClick={() => transfer("/my-account")}
                      >
                        My Account
                      </div>
                      <div
                        className="cursor-pointer text-[13px]  text-text hover:text-white"
                        onClick={logoutHandler}
                      >
                        Logout
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className="bg-gradient-to-bl from-primary-100 to-[#9fbee9] bg-clip-text px-1 text-sm font-bold text-transparent "
              >
                Login
              </button>
            )}
          </div>
        </div>
        {search !== "" && (
          <div className="absolute top-full left-0 z-50 w-full bg-secondary2">
            {loading ? (
              <div className="flex h-full items-center justify-center">
                <Loading2 />
              </div>
            ) : (
              <div className="flex flex-col gap-2 px-4 py-2">
                <div className="text-[13px] font-bold text-white">
                  Search Results
                </div>
                {data.movies.length > 0 && (
                  <VideoCard
                    data={{
                      name: "Movies",
                      imageType: "Horizontal",
                      content: data.movies.map((item) => ({
                        item: item,
                        kind: "movie",
                      })),
                    }}
                  />
                )}
                {data.series.length > 0 && (
                  <VideoCard
                    data={{
                      name: "Series",
                      imageType: "Horizontal",
                      content: data.series.map((item) => ({
                        item: item,
                        kind: "series",
                      })),
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}

        {notification && (
          <div className="fixed top-0 right-0 z-50 h-full bg-secondary3 py-2 px-3">
            <div className="flex justify-between gap-10 ">
              <p className="px-4 py-2 text-[13px] font-bold text-white">
                Notifications
              </p>
              <button
                className="text-white"
                onClick={() => setNotification(false)}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="flex flex-col items-center justify-center gap-3 py-4">
              {loading2 ? (
                <Loading2 />
              ) : (
                notificationData.map((item) => <Notification item={item} />)
              )}
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;

const Notification = ({ item }) => {
  const [show, setShow] = useState(false);
  const [read, setRead] = useState(false);
  const user = useStore((state) => state.user);

  const markAsReadHandler = async () => {
    const [success, response] = await markAsRead(user.token, item._id);
    if (success) {
      setRead(true);
    }
  };

  return (
    <button
      className="flex  gap-4 border-b border-gray-600 px-4 pb-4 "
      onClick={() => {
        setShow(!show);
        if (!read) {
          markAsReadHandler();
        }
      }}
    >
      <img
        src={item.image}
        alt={item.name}
        className="co h-12 w-12 rounded bg-white"
      />
      <div className="flex flex-col gap-2">
        <div className="flex  gap-3 text-white">
          <div>
            <p className="text-[13px] font-bold ">{item.title}</p>
            <p className="text-left text-xs ">{item.date}</p>
          </div>
          <FiChevronRight
            className={` ${show ? "-rotate-90" : "rotate-90"} transition-all`}
          />
        </div>
        {show && (
          <div className="text-left text-[13px] text-gray-400">
            {item.description}
          </div>
        )}
      </div>
    </button>
  );
};
